import { AsyncAction } from './AsyncAction';
import { immediateProvider } from './immediateProvider';
export class AsapAction extends AsyncAction {
  constructor(scheduler, work) {
    super(scheduler, work);
    this.scheduler = scheduler;
    this.work = work;
  }
  requestAsyncId(scheduler, id, delay = 0) {
    if (delay !== null && delay > 0) {
      return super.requestAsyncId(scheduler, id, delay);
    }
    scheduler.actions.push(this);
    return scheduler._scheduled || (scheduler._scheduled = immediateProvider.setImmediate(scheduler.flush.bind(scheduler, undefined)));
  }
  recycleAsyncId(scheduler, id, delay = 0) {
    if (delay != null && delay > 0 || delay == null && this.delay > 0) {
      return super.recycleAsyncId(scheduler, id, delay);
    }
    if (!scheduler.actions.some(action => action.id === id)) {
      immediateProvider.clearImmediate(id);
      scheduler._scheduled = undefined;
    }
    return undefined;
  }
}
