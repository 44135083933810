import { Inject, Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Injectable({
  providedIn: "root"
})
export class SnackbarService {
  private static service: MatSnackBar | undefined = undefined;

  public constructor(@Inject(MatSnackBar) service: MatSnackBar) {
    SnackbarService.service = service;
  }

  public static getService(): MatSnackBar {
    if (!SnackbarService.service) {
      throw new Error('SnackbarService not initialized');
    }
    return SnackbarService.service;
  }

  public static show(message: string, action: string = 'OK', duration: number = 1500) {
    return SnackbarService.getService().open(message, action, { duration, panelClass: ['light-snack-bar'] });
  }

  public static showFromComponent(
    component: any,
    options: any
  ) {
    SnackbarService.getService().openFromComponent(component, options);
  }

  public static dismiss() {
    SnackbarService.getService().dismiss();
  }
}
