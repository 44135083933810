import { Inject, Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ProgressComponent } from "../components/progress/progress.component";

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  private static service: MatSnackBar | undefined = undefined;

  public constructor(@Inject(MatSnackBar) service: MatSnackBar) {
    LoaderService.service = service;
  }

  public static getService(): MatSnackBar {
    if (!LoaderService.service) {
      throw new Error('LoaderService not initialized');
    }
    return LoaderService.service;
  }

  public static showFromComponent(
    options: any
  ) {
    LoaderService.getService().openFromComponent(ProgressComponent, options);
  }

  public static dismiss() {
    LoaderService.getService().dismiss();
  }
}
