var Queue = function () {
  this.head = null;
  this.tail = null;
};
Queue.prototype = {
  add: function (item) {
    var entry = {
      item: item,
      next: null
    };
    if (this.head) this.tail.next = entry;else this.head = entry;
    this.tail = entry;
  },
  get: function () {
    var entry = this.head;
    if (entry) {
      this.head = entry.next;
      if (this.tail === entry) this.tail = null;
      return entry.item;
    }
  }
};
module.exports = Queue;