var $ = require('../internals/export');
var DESCRIPTORS = require('../internals/descriptors');
var defineProperty = require('../internals/object-define-property').f;

// `Object.defineProperty` method
// https://tc39.es/ecma262/#sec-object.defineproperty
// eslint-disable-next-line es-x/no-object-defineproperty -- safe
$({
  target: 'Object',
  stat: true,
  forced: Object.defineProperty !== defineProperty,
  sham: !DESCRIPTORS
}, {
  defineProperty: defineProperty
});