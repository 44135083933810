import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localeDe from '@angular/common/locales/de';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  defaultLanguage = 'de';

  constructor(
    private translate: TranslateService
  ) { }

  init() {
    registerLocaleData(localeDe, 'de');
    this.translate.use(this.getLanguage());
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translate.use(language);
  }

  getLanguage() {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      return storedLanguage;
    }

    return this.defaultLanguage;
  }
}
